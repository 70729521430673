import React, { useEffect, useRef, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';

const CustomBarChart = ({data}) => {
  const chartRef = useRef(null);
  const [size, setSize] = useState(400);

  useEffect(() => {
    updateSize();
    window.addEventListener('resize', updateSize);

    return() => {
      window.removeEventListener('resize', updateSize);
    }
  },[]);

  const updateSize = () => {
    if(chartRef.current) {
      const rect = chartRef.current.getBoundingClientRect();
      const width = rect.width
      if(width > 600) {
        setSize(width);
      } else {
        setSize(600);
      }
    }
  };

  const Label = ({ x, y, width, height, value }) => {
    const textX = x + width + 17;
    const textY = y + 12;
  
    return (
      <text x={textX} y={textY} fill="#000" textAnchor="middle" dominantBaseline="middle" fontSize="12px">
        {`${value}%`}
      </text>
    );
  };

  const XTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#000">
          {`${payload.value}%`}
        </text>
      </g>
    );
  };

  const YTick = ({ x, y, payload }) => {
    return (
      <text x={x} y={y} dy={4} textAnchor="end" fill="#000" fontSize="12px">
        {payload.value}
      </text>
    );
  };

  return (
    <div ref={chartRef}>
      <div style={{ width: `${size}px`, height: '400px' }}>
        <BarChart
          width={Math.max(700, size)}
          height={400-5-5}
          data={data}
          layout="vertical"
          margin={{
            top: 5, right: 16, left: 300, bottom: 5,
          }}
        >
          <CartesianGrid horizontal={false} />
          <Bar
            barSize={20}
            dataKey="value"
            fill="#5EE2E2" 
            radius={[0, 20, 20, 0]}
            label={<Label />}
          />
          <XAxis
            type="number"
            domain={[0, 75]}
            ticks={[0, 25, 50, 75]}
            tick={<XTick />}
            tickLine={false}
          />
          <YAxis
            type="category"
            dataKey="name"
            tick={<YTick />}
            tickLine={false}
          />
        </BarChart>
      </div>
    </div>
  );
}

export default CustomBarChart;
