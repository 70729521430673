import React, { useEffect, useRef, useState } from 'react';
import { PieChart, Pie, Cell, Text, Legend, ResponsiveContainer } from 'recharts';

const COLORS = ['#5EE2E2', '#37AACA','#2D8BBA'];


const CustomPieChart = ({data}) => {
  const chartRef = useRef(null);
  const [size, setSize] = useState(400);
  useEffect(() => {
    updateSize();
    window.addEventListener('resize', updateSize);

    return() => {
      window.removeEventListener('resize', updateSize);
    }
  },[]);

  const updateSize = () => {
    if(chartRef.current) {
      const rect = chartRef.current.getBoundingClientRect();
      setSize(rect.width/2);
    }
  };

  const label = ({name, value, cx, cy, midAngle, innerRadius, outerRadius}) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <>
        <Text x={x} y={y} fill="black" textAnchor='start' scaleToFit="true">{name}</Text>
        <Text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} fill="white" dominantBaseline="central">{`${value}%`}</Text>
      </>
    );
  };

  const aspectRatio = (() => {
    if(data.length < 3) {
      return 1/1.25;
    } else {
      if(size < 120) {
        return 1/1.8
      } else if(size < 200) {
        return 1/1.5
      } else {
        return 1/1.25
      }
    }
  })();

  return (
      <div ref={chartRef}>
        <ResponsiveContainer width='100%' height='100%' aspect={aspectRatio}>
          <PieChart>
            <Legend 
              iconType="square" 
              layout="vertical" 
              verticalAlign="bottom" 
              align="center"
              wrapperStyle={{ paddingBottom: "12px", paddingTop: "-45px" }}
              formatter={(value, entry, index) => <span className="recruit-custom__legend-text-format">{value}</span>}
              height={data.length <= 3 ? 'auto' : 72}
            />
            <Pie
              data={data}
              isAnimationActive={false}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={size-20}
              innerRadius={(size/2)-10}
              label={label}
              labelLine={false}
              activeShape={null}
              startAngle={90}
              endAngle={450}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index]} />
              ))}
            </Pie>
          </PieChart>
          
        </ResponsiveContainer>
      </div>
)};

export default CustomPieChart;
