import React from 'react'
import Layout from '../components/Layout'
import Header from '../components/Header'
import CustomPieChart from '../components/CustomPieChart'
import CustomBarChart from '../components/CustomBarChart'
import { Link } from 'gatsby'

const RecruitPageContent = () => (
  <div className="main">
    <section className="section">
      <div className="section__inner">
        <h2 className="recruit-merit__title">【「もばらぶ」で働く魅力】</h2>
        <div className="recruit-merit__container">
          <div className="recruit-card__section">
            {/** Content Start **/}
            <h3 className='recruit-merit__section-title'>① 柔軟な働き方</h3>
            <div className="recruit-custom__container">
               <div className="recruit-custom__row recruit-custom__align-items-top">
                 <div className='recruit-custom__col recruit-custom__col-md-12 recruit-custom__col-sm-12'>
                   <div className='recruit-custom__card recruit-custom__card-md recruit-custom__card-sm  recruit-custom__align-items-center'>
                     <h3 className='recruit-custom__section-title'>働く場所を選択できます</h3>
                     <ul className='recruit-custom__section-list'>
                      <li className='recruit-custom__section-text'>地方・海外在住の方も働けます</li>
                     </ul>
                     <p className='recruit-custom__section-text'>※海外在住者は法的な制約により業務委託契約となる</p>
                     <div className="recruit-custom__charts">
                       <CustomPieChart data={[{name: 'フルリモートワーク', value: 100}]}/>
                      </div>
                   </div>
                 </div>
                 <div className='recruit-custom__col recruit-custom__col-md-12 recruit-custom__col-sm-12'>
                   <div className='recruit-custom__card recruit-custom__card-md recruit-custom__card-sm  recruit-custom__align-items-center'>
                     <h3 className='recruit-custom__section-title'>働く時間を選択できます</h3>
                     <ul className='recruit-custom__section-list'>
                      <li className='recruit-custom__section-text'>フレックス制（コアタイム12-14時）</li>
                      <li className='recruit-custom__section-text'>プライベートに合わせた時短勤務が可能</li>
                      <li className='recruit-custom__section-text'>小さなお子様のいる方も安心して働けます</li>
                     </ul>
                    <div className="recruit-custom__charts">
                       <CustomPieChart data={[{name: 'フルタイム', value: 34, x: 40, y: 80},{name: '時短勤務', value:66 }]}/>
                    </div>
                   </div>
                 </div>
                 <div className='recruit-custom__col recruit-custom__col-md-12 recruit-custom__col-sm-12'>
                   <div className='recruit-custom__card recruit-custom__card-md recruit-custom__card-sm recruit-custom__align-items-center'>
                     <h3 className='recruit-custom__section-title'>契約形態を選択できます</h3>
                     <ul className='recruit-custom__section-list'>
                      <li className='recruit-custom__section-text'>兼業・副業を推進しています</li>
                      <li className='recruit-custom__section-text'>起業を考えている方にも最適です</li>
                     </ul>
                     <p className='recruit-custom__section-text'>※兼業・副業は許可制、直接の競合他社を除く</p>
                     <div className="recruit-custom__charts">
                       <CustomPieChart data={[{name: '業務委託', value: 45},{name: '雇用契約', value:55 }]}/>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
             {/** Second Section Pie Chart **/}
             <h3 className='recruit-merit__section-title'>② グローバルな環境</h3>
             <div className="recruit-custom__container">
               <div className='recruit-custom__row recruit-custom__align-items-top'>
                 <div className='recruit-custom__col recruit-custom__col-md-12 recruit-custom__col-sm-12'>
                   <div className='recruit-custom__card recruit-custom__card-md recruit-custom__card-sm  recruit-custom__align-items-center'>
                     <h3 className='recruit-custom__section-title recruit-custom__mt-4'>国内外に居住するメンバー</h3>
                     <div className="recruit-custom__charts">
                       <CustomPieChart data={[{name: '海外居住', value: 14},{name: '国内居住', value:86 }]}/>
                     </div>
                   </div>
                 </div>
                 <div className='recruit-custom__col recruit-custom__col-md-12 recruit-custom__col-sm-12'>
                   <div className='recruit-custom__card recruit-custom__card-md recruit-custom__card-sm recruit-custom__align-items-center recruit-custom__card'>
                      <div className='recruit-merit__icon recruit-custom__center-vertical recruit-custom__center-vertical-md recruit-custom__center-vertical-sm'>
                        <img className="recruit-custom__default-img" src="/image/common/recruit/english.svg" alt="" />
                      </div>
                     <h3 className='recruit-custom__section-title'>英語力向上サポート</h3>
                     <ul className='recruit-custom__section-list'>
                      <li className='recruit-custom__section-text'>社内英語講師によるIT英会話レッスン</li>
                      <li className='recruit-custom__section-text'>社外英会話レッスン費補助</li>
                     </ul>
                   </div>
                 </div>
                 <div className='recruit-custom__col recruit-custom__col-md-12 recruit-custom__col-sm-12'>
                   <div className='recruit-custom__card recruit-custom__card-md recruit-custom__card-sm  recruit-custom__align-items-center recruit-custom__card'>
                    <div className='recruit-merit__icon recruit-custom__center-vertical recruit-custom__center-vertical-md recruit-custom__center-vertical-sm'>
                      <img className="recruit-custom__default-img" src="/image/common/recruit/world.svg" alt="" />
                    </div>
                    <h3 className='recruit-custom__section-title'>海外プロジェクトに携われ<br></br>実践的な英語習得が可能</h3>
                   </div>
                 </div>
               </div>
             </div>
             {/** Third Section **/}
             <h3 className='recruit-merit__section-title'>③ 充実した福利厚生制度</h3>
             <div className='recruit-custom__container'>
               <div className='recruit-custom__row recruit-custom__align-items-top'>
                 <div className='recruit-custom__col recruit-custom__col-md-12 recruit-custom__col-sm-12'>
                   <div className='recruit-custom__card recruit-custom__card-md recruit-custom__card-sm  recruit-custom__align-items-center recruit-custom__space-circle-icon recruit-custom__circle-icon-md recruit-custom__circle-icon-sm'>
                     <div className='recruit-custom__circle-icon-article recruit-custom__circle-icon-img'>
                       <img className='recruit-custom__default-img' src='/image/common/recruit/book.png' alt=""></img>
                     </div>
                     <h3 className='recruit-custom__section-title'>メンバーの教育に力を入れています</h3>
                     <ul className='recruit-custom__section-list'>
                      <li className='recruit-custom__section-text'>ITエンジニア向けオンライン英会話レッスン社内サービス「モバプロ」無料受講</li>
                      <li className='recruit-custom__section-text'>社外オンライン英会話レッスン費用負担</li>
                      <li className='recruit-custom__section-text'>業務に関する書籍代費用負担</li>
                     </ul>
                   </div>
                 </div>
                 <div className='recruit-custom__col recruit-custom__col-md-12 recruit-custom__col-sm-12'>
                   <div className='recruit-custom__card recruit-custom__card-md recruit-custom__card-sm  recruit-custom__align-items-center recruit-custom__space-circle-icon recruit-custom__circle-icon-md recruit-custom__circle-icon-sm'>
                     <div className='recruit-custom__circle-icon-article recruit-custom__circle-icon-img'>
                       <img className='recruit-custom__default-img' src='/image/common/recruit/gender-equality.png' alt=""></img>
                     </div>
                     <h3 className='recruit-custom__section-title'>女性や子育て中の人にとって<br></br>働きやすい会社です</h3>
                     <ul className='recruit-custom__section-list'>
                      <li className='recruit-custom__section-text'>不妊治療休暇</li>
                      <li className='recruit-custom__section-text'>産後パパ育休</li>
                     </ul>
                   </div>
                 </div>
                 <div className='recruit-custom__col recruit-custom__col-md-12 recruit-custom__col-sm-12'>
                   <div className='recruit-custom__card recruit-custom__card-md recruit-custom__card-sm  recruit-custom__align-items-center recruit-custom__space-circle-icon recruit-custom__circle-icon-md recruit-custom__circle-icon-sm'>
                     <div className='recruit-custom__circle-icon-article recruit-custom__circle-icon-img'>
                       <img className='recruit-custom__default-img' src='/image/common/recruit/networking.png' alt=""></img>
                     </div>
                     <h3 className='recruit-custom__section-title'>メンバーとのコミュニケーションや繋がりを<br></br>大切にしています</h3>
                     <ul className='recruit-custom__section-list'>
                      <li className='recruit-custom__section-text'>オンラインランチ会費用負担</li>
                      <li className='recruit-custom__section-text'>リファラル制度</li>
                     </ul>
                   </div>
                 </div>
               </div>
             </div>
             {/** Fourth Section **/}
             <h3 className='recruit-merit__section-title recruit-custom__mb-1 recruit-custom__mt-3'>【メンバーの声】</h3>
             <div className='recruit-custom__container'>
               <div className='recruit-custom__row recruit-custom__align-items-top'>
                 <div className='recruit-custom__col recruit-custom__col-md-12 recruit-custom__col-sm-12'>
                   <div className='recruit-custom__card recruit-custom__card-md recruit-custom__card-sm  recruit-custom__align-items-center recruit-custom__card recruit-custom__card-sm-h2 recruit-custom__card-h2'>
                     <h3 className='recruit-custom__section-title'>もばらぶで働こうと思ったきっかけ</h3>
                     <div className="recruit-custom__charts recruit-custom__align-items-top">
                       <CustomPieChart data={[{name: '応募の職種に興味があった（自分の領域である）', value: 7},{name: '会社の理念や考え方が自分にあっていると感じた', value:13 },{name: 'フルリモートでの勤務に魅力を感じた', value:80 }]}/>
                     </div>
                   </div>
                 </div>
                 <div className='recruit-custom__col recruit-custom__col-8 recruit-custom__col-md-12 recruit-custom__col-sm-12'>
                   <div className='recruit-custom__card recruit-custom__card-md recruit-custom__card-sm  recruit-custom__align-items-center recruit-custom__card recruit-custom__card-sm-h2 recruit-custom__card-h2'>
                     <h3 className='recruit-custom__section-title recruit-custom__mt-4'>「もばらぶ」で働く魅力とは？</h3>
                     <div className="recruit-custom__charts recruit-custom__charts--bar">
                      <CustomBarChart data={[
                        { name: '多様なバックグラウンドを持ったメンバーが多い', value: 67 },
                        { name: '新しい技術やプロジェクトに触れる機会が多い', value: 67 },
                        { name: 'スキルが高いメンバーが多い', value: 60 },
                        { name: 'お互いアイデアを出したり、協力して仕事を進めることができる', value: 53 },
                        { name: '自分の専門分野に集中して仕事に取り組むことができる', value: 40 },
                        { name: '物事の決定が早い、意思決定がスムーズ', value: 33 },
                        { name: '自由に意見を言える', value: 33 },
                        { name: 'その他', value: 7 },
                      ]}/>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           {/** Content End **/}
          </div>
        </div>
      </div>
    </section>
    <section className="section section--gray">
      <div className="section__inner">
        <h2 className="recruit__jobs-title">募集中の職種</h2>
        <div className="recruit__button-area">
          <Link className="btn-recruit" to="/recruit/business-development">事業開発</Link>
        </div>
        <div className="recruit__button-area">
          <Link className="btn-recruit" to="/recruit/project-manager">プロジェクトマネージャー</Link>
        </div>
        <div className="recruit__button-area">
          <Link className="btn-recruit" to="/recruit/software-engineer">ソフトウェアエンジニア</Link>
        </div>
        <div className="recruit__button-area">
          <Link className="btn-recruit" to="/recruit/designer">デザイナー</Link>
        </div>
        <p className="recruit__link-txt">採用の流れについては弊社ブログの<a href="https://blog.mobalab.net/2021/02/05/%e5%bc%8a%e7%a4%be%e3%81%ae%e6%8e%a1%e7%94%a8%e3%81%ae%e6%b5%81%e3%82%8c%e3%81%aa%e3%81%a9%ef%bc%88%e5%bf%9c%e5%8b%9f%e8%80%85%e5%90%91%e3%81%91%ef%bc%89/" target="_blank" rel="noreferrer" className="service-page__text-link">こちらの記事</a>をご参照ください。</p>
      </div>
    </section>
  </div>
)

const RecruitPage = (props) => (
  <Layout
    title="採用情報 | 株式会社もばらぶ"
    description="株式会社もばらぶは、外房は千葉県茂原市、九十九里の海沿いにある、遠隔勤務など新しい働き方を推進している、IT企業です。フルリモートでの採用を行っていますので、ご興味のある方はお気軽にご応募ください。"
    pathName={props.location.pathname}
  >
    <Header />
    <div className="recruit">
      <div className="recruit__title-area">
        <h1 className="recruit__title">RECRUIT</h1>
        <p className="recruit__title-ja">採用情報</p>
        <p className="recruit__body">もばらぶはメンバーを募集中です。</p>
      </div>
      <p className="recruit__catchphrase">働く場所・時間帯を<br/>働く人が自由に選べるような社会にする</p>
    </div>
    <RecruitPageContent />
  </Layout>
)
export default RecruitPage